var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-dept", {
                  attrs: {
                    editable: _vm.editable,
                    label: "관리부서",
                    name: "deptCd",
                  },
                  model: {
                    value: _vm.searchParam.deptCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "deptCd", $$v)
                    },
                    expression: "searchParam.deptCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-func-location", {
                  attrs: {
                    type: "search",
                    plantCd: _vm.searchParam.plantCd,
                    editable: _vm.editable,
                    label: "기능위치",
                    name: "funcLocationCd",
                  },
                  model: {
                    value: _vm.searchParam.funcLocationCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "funcLocationCd", $$v)
                    },
                    expression: "searchParam.funcLocationCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-equip-class", {
                  attrs: {
                    editable: _vm.editable,
                    label: "설비유형",
                    name: "equipmentTypeCd",
                  },
                  model: {
                    value: _vm.searchParam.equipmentTypeCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "equipmentTypeCd", $$v)
                    },
                    expression: "searchParam.equipmentTypeCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    comboItems: _vm.discardFlagItems,
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "discardFlag",
                    label: "사용여부",
                  },
                  model: {
                    value: _vm.searchParam.discardFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "discardFlag", $$v)
                    },
                    expression: "searchParam.discardFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "설비 목록",
                  tableId: "equipmentTable",
                  selection: "multiple",
                  rowKey: "equipmentCd",
                  columns: _vm.grid.columns,
                  data: _vm.grid.data,
                },
                on: { linkClick: _vm.linkClick },
                scopedSlots: _vm._u([
                  {
                    key: "customArea",
                    fn: function ({ props, col }) {
                      return [
                        col.name === "discardFlag"
                          ? [
                              _c(
                                "q-chip",
                                {
                                  attrs: {
                                    color:
                                      props.row.discardFlag == "N"
                                        ? "blue"
                                        : "red",
                                    "text-color": "white",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(props.row.discardFlagName) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          : col.name === "bom"
                          ? [
                              _c(
                                "q-chip",
                                {
                                  attrs: { color: "gray", clickable: true },
                                  on: {
                                    click: function ($event) {
                                      return _vm.rowClick(props.row)
                                    },
                                  },
                                },
                                [_vm._v(" Spare Part ")]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: {
                            label: "QR코드 출력",
                            editable: _vm.editable,
                            icon: "save_alt",
                          },
                          on: { btnClicked: _vm.btnQrCode },
                        }),
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "등록", icon: "add" },
                              on: { btnClicked: _vm.openPop },
                            })
                          : _vm._e(),
                        _c("c-btn", {
                          attrs: { label: "검색", icon: "search" },
                          on: { btnClicked: _vm.getList },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }