<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            :editable="editable"
            label="관리부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-func-location 
            type="search" 
            :plantCd="searchParam.plantCd" 
            :editable="editable" 
            label="기능위치" 
            name="funcLocationCd" 
            v-model="searchParam.funcLocationCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-equip-class
            :editable="editable"
            label="설비유형"
            name="equipmentTypeCd"
            v-model="searchParam.equipmentTypeCd">
          </c-equip-class>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사용여부 -->
          <c-select
            :comboItems="discardFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="discardFlag"
            label="사용여부"
            v-model="searchParam.discardFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="설비 목록"
          tableId="equipmentTable"
          selection="multiple"
          rowKey="equipmentCd"
          :columns="grid.columns"
          :data="grid.data"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="QR코드 출력" :editable="editable" icon="save_alt" @btnClicked="btnQrCode" />
              <c-btn v-if="editable" label="등록" icon="add" @btnClicked="openPop" />
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'discardFlag'">
              <q-chip
                :color="props.row.discardFlag=='N' ? 'blue' : 'red'"
                text-color="white">
                {{props.row.discardFlagName}}
              </q-chip>
            </template>
            <template v-else-if="col.name === 'bom'">
              <q-chip
                color="gray"
                :clickable="true"
                @click="rowClick(props.row)">
                Spare Part
              </q-chip>
            </template>
          </template>
        </c-table>
        <c-dialog :param="popupOptions"></c-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-manager',
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: null,
        funcLocationCd: '',
        equipmentName: '',
        equipmentTypeCd: '',
        grade: null,
        discardFlag: null,
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      discardFlagItems: [
        { code: 'N', codeName: '가동중' },
        { code: 'Y', codeName: '폐기' },
      ],
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width: 120px',
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '설비Tag',
            align: 'center',
            sortable: true,
            style: 'width: 150px',
          },
          {
            name: 'equipmentNum',
            field: 'equipmentNum',
            label: '설비관리번호',
            align: 'center',
            sortable: true,
            style: 'width: 150px',
          },
          {
            name: 'discardFlag',
            field: 'discardFlag',
            label: '설비상태',
            align: 'center',
            type: 'custom',
            sortable: true,
            style: 'width: 140px',
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'left',
            sortable: true,
            style: 'width: 250px',
            type: 'link'
          },
          // {
          //   name: 'sparepart',
          //   field: 'sparepart',
          //   label: 'spare part',
          //   align: 'center',
          //   type: 'custom',
          //   sortable: true,
          //   style: 'width: 100px',
          // },
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            align: 'center',
            sortable: true,
            style: 'width: 250px',
          },
          // {
          //   name: 'processName',
          //   field: 'processName',
          //   label: '단위공정',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width: 200px',
          // },
          {
            name: 'managementDeptName',
            field: 'managementDeptName',
            label: '관리부서',
            align: 'center',
            sortable: true,
          },
          {
            name: 'funcLocationName',
            field: 'funcLocationName',
            label: '기능위치',
            align: 'center',
            sortable: true,
          },
          {
            name: 'costCenterName',
            field: 'costCenterName',
            label: '코스트센터',
            align: 'center',
            sortable: true,
          },
          // {
          //   name: 'equipGradeName',
          //   field: 'equipGradeName',
          //   label: '설비등급',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width: 100px',
          // },
        ],
        data: [],
      },
      editable: true,
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.mdm.equipment.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '설비 상세';
      this.popupOptions.param = {
        equipmentCd: row ? row.equipmentCd : '',
        plantCd: row ? row.plantCd : '',
      };
      this.popupOptions.target = () => import(`${'./equipmentDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    rowClick(row) {
      this.popupOptions.title = '설비 상세';
      this.popupOptions.param = {
        equipmentCd: row ? row.equipmentCd : '',
        plantCd: row ? row.plantCd : '',
        tabName: 'equipmentEtc',
      };
      this.popupOptions.target = () => import(`${'./equipmentDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    btnQrCode() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // '안내',
          message: '설비 목록 중 하나 이상 선택하세요.', // '설비 목록 중 하나 이상 선택하세요.',
          type: 'warning',
        });
        return;
      }
      if (selectData.length > 10) {
        window.getApp.$emit('ALERT', {
          title: '안내', // '안내',
          message: 'QR코드 일괄출력은 한번에 10개까지 가능합니다.', // 'QR코드 일괄출력은 한번에 10개까지 가능합니다.',
          type: 'warning',
        });
        return;
      }
      this.popupOptions.title = "설비 QR코드"; 
      this.popupOptions.param = {
        selectData: selectData,
      };
      this.popupOptions.target = () =>
        import(`${"./equipmentQr.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '800px';
      this.popupOptions.closeCallback = this.closePopup;
    },
  }
};
</script>
